@import "./Constants.scss";

.expense-steps-cont {
  background: rgba(0,0,0,.01);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: .5rem;

  .expense-step {
    .expense-step-number {
      border: 2px solid rgba(0,0,0,.2);
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: rgba(0,0,0,.6);
      // font-weight: bold;
    }
    .expense-step-number.checked {
      background: green;
      // color: darkgreen;
      color: rgba(255,255,255,.8);
    }
    .expense-step-arrow {
      color: rgba(0,0,0,.2);
      margin: 0 1em;
    }
  }
}



.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.card {
  padding: 2.5em;
  padding-right: 5em;
  padding-top: 2.5em;
  border-radius: 1.5em;
  
  margin-right: 2em;

  transition: .2s all;


  .title {
    font-size: 1.25em;
    font-weight: bold;
  }
}

.card.outline {
  border: 2px solid rgba(0,0,0,.1);
}
.card.outline:hover {
  box-shadow: 0 .5em 1em rgba(0,0,0,.1);
  transition: .2s all;
}
.card.shadow {
  box-shadow: 0 .5em 1em rgba(0,0,0,.1);
  transition: .2s all;
}


.support-cont {

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  // background: rgba(0,0,0,.5);
  z-index: 9999999999999;
  pointer-events: none;

  .support-bg {
    background: rgba(0,0,0,.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: all;
    cursor: pointer;
  }

  .support-dialog {
    background: white;
    position: absolute;
    bottom: 9em;
    right: 1.25em;
    top: 2em;

    width: 25em;
    // height: 20em;
    border-radius: 1em;
    padding: 2em;
    pointer-events: all;
    border-bottom-right-radius: 0;
    overflow: scroll;

    .button.full {
      box-sizing: border-box;
    }


    textarea {
      transition: none;
      width: 100%;
      height: 15em;
    }
    
  }




  .support-button {
    pointer-events: all;
    position: fixed;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    font-size: 2em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    right: .5em;
    bottom: 2em;
    
    box-shadow: 0 0 .5em rgba(0,0,0,.1);
    color: gray;
    cursor: pointer;
    transition: all .2s;
  
    i:hover {
      color: gray !important;
    }
  }
  .support-button:hover {
    box-shadow: 0 .25em 1em rgba(0,0,0,.1);
    transform: scale(1.1);
  }
}




.alpha-entry-row.total {
  border-top: 4px solid rgba(0,0,0,.1);
  margin-top: 1rem;
}

.alpha-timesheet-footer {
  position: fixed;
  bottom: -10px;
  left: 0;
  right: 0;
  padding: .5em;
  padding-bottom: .5em;
  font-size: .8rem;
  background: white;
  box-shadow: 0em -0.5em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.25s;
  z-index: 89000;
  pointer-events: none;


  .week {
    width: 12em;
    text-align: center;
    font-weight: bold;
  }

  .week-selector-cont {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 9999px;
  }

  .selector-disabled {
    opacity: .3;
    pointer-events: none;
  }

  .week-selector {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    // background: rgba(0,0,0,.05);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;


  }

  .week-selector:hover {
    background: $yellow;
  }
}
.alpha-timesheet-footer.scrolled {
  bottom: 0;
  opacity: 1;
  pointer-events: all;
}
.alpha-error-cont:last-of-type {
  margin-bottom: 1rem;
}

.alpha-error-cont.managers-note {
  color: rgb(101, 101, 101);
  font-weight: bold;
  line-height: 1;
  font-size: 0.85rem;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: rgba(139, 0, 0, 0.1);
  margin-top: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, .025);
}

.alpha-error-cont {

  color: darken(red, 0);
  font-weight: bold;
  line-height: 1;
  font-size: .85rem;
  border: 2px solid rgba(red, .1);
  background: rgba(darkred, .1);
  margin-top: .25rem;
  // margin-bottom: 1rem;
  padding: .5rem;
  border-radius: .5rem;
  // border: 2px solid red;
  background: rgba(244, 231, 230, 1);

  ul {
    margin: 0;
    padding: 0;
    padding-left: 2em;

    li {
      padding: 0;
      margin: 0;
    }
  }
  
}

.alpha-entry-status {
  margin-bottom: 1px;
  background: rgba(0,0,0,.1);
  padding: .35em .25em;
  font-weight: bold;
  color: rgba(0,0,0,.5);
  width: 7.5em;
  text-align: center;
  border-radius: 2px;
  text-transform: uppercase;
}

.alpha-timesheet-status {
  padding: .33em 1em;
  background-color: transparentize(black ,.9);
  color: gray;
  border-radius: 2px;
  text-transform: uppercase;
}

// .status-badge-cont.posted {
//   .status-badge {
//     background: forestgreen;
//   }
//   .status-message {
//     color: forestgreen;
//   }
// }

.alpha-entry-status.posted, .alpha-timesheet-status.posted {
  background: forestgreen;
  color: white;
}

.alpha-entry-status.returned, .alpha-timesheet-status.returned {
  background-color: transparentize($dangerRed ,.8);
  color: $dangerRed;
}
.alpha-entry-status.approved, .alpha-timesheet-status.approved {
  background-color: transparentize($successGreen ,.8);
  color: $successGreen;
}

.alpha-entry-status.pending, .alpha-timesheet-status.pending {
  background-color: transparentize($yellow ,.8);
  color: darken($yellow, 15);
}
.alpha-entry-status.new, .alpha-timesheet-status.new {
  background-color: transparentize(black ,.9);
  color: gray;
}
.alpha-entry-status.draft, .alpha-timesheet-status.draft {
  background-color: transparentize(black ,.9);
  color: gray;
}

.alpha-entry-status.submitted, .alpha-timesheet-status.submitted {
  // background-color: transparentize(black ,.8);
  // background: none;
  // color: gray;
  text-transform: uppercase;
  // background: rgba(0,0,0,.05);
}




.alpha-entry-row {
  // background: white;
  // border-bottom: 1px solid rgba(0,0,0,.1);
  
  // display: flex;
  // align-items: center;
  font-size: 12px;

  .alpha-note-clicker {
    padding: 3px;
    cursor: pointer;
    border-radius: 4px;
    position: relative;

    // i {
    //   position: absolute;
    //   right: 0;
    //   bottom: 3px;
    // }

  }
  .alpha-note-clicker:hover {
    background: rgba($yellow, .5);
  }

  .alpha-note-clicker.note-present {
    background: $yellow;
    color: black;
    // i {
    //   display: inline-flex;
    //   align-items: center;
    //   justify-content: center;
    //   width: 2em;
    //   height: 2em;
    //   padding: 0;
    //   border-radius: 50%;
    //   background: $yellow;
    //   color: black;
    //   bottom: 0;

    // }
  }

  

  .alpha-entry-cell {
    // padding: 4px;
    // margin-right: 4px;
    padding: .25rem 0;
    margin-right: 2px;
    padding-right: 2px; 
    white-space: nowrap;
    // border: 1px solid rgba(0,0,0,.1);
    
    // font-size: 12px;
    // border-right: 1px solid rgba(0,0,0,.1);

    input[type=text], .editable-cont {
      // width: 100%;
      min-width: 100%;
      padding: .25em;
      font-size: 1em;
    }
    
  }

  .entry-total-hours {
    // min-width: 6em;
    // width: 6em;
    font-weight: bold;
    border-color: transparent;
    text-align: center;
    // font-size: 14px;
  }

  .label {
    // margin-bottom: 3px;
    // font-size: .9em;
    // color: rgba(0,0,0,.5);
    // font-weight: bold;
  }

  .entry-cell-label {
    // margin-bottom: 3px;
    font-size: .8em;
    // font-size: .7em;
    color: rgba(0,0,0,.5);
    font-weight: bold;
  }
  .entry-cell-label.has-errors {
    color: red;
  }

  .alpha-entry-dropdown-cont {
    // width: 12em;
    // height: 2em;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .full-date {
    margin-bottom: .5em;
    font-size: 1.25em;
    font-weight: normal;
  }
  .alpha-entry-date.blank {
    color: rgba(0,0,0,.25);
  }
  .alpha-entry-autocomplete {
    min-width: 20em;
    width: 20em;
    position: relative;

    .hover {
      opacity: .1;
      // scale: 0;
      background: white;
      color: black;
      box-shadow: .2em 0em 1em rgba(0,0,0,.1);
      // padding: 1em;
      position: absolute;
      left: 0;
      top: 0;
      transition: all .2s;
      z-index: 9999;
      pointer-events: none;
    }
  }
  .alpha-entry-autocomplete:hover {
    
    .hover {
      opacity: 1;
      transform: all .2s;
      // scale: 1;
    }
  }
  .alpha-entry-date {
    min-width: 5em;
    width: 5em;
    // font-size: 14px;

    

    .date {
      // font-size: .7em;
      // color: rgba(0,0,0,.5);
    }
  }
}

.align-items-end {
  align-items: flex-end;
}

.th-clicker {
  cursor: pointer;
  transition: .2s all;
  outline-style:none;
}
.th-clicker:hover, .th-clicker.current:hover {
  color: $yellow;
  transition: .2s all;
}
.th-clicker.current {
  color: black;
  transition: .2s all;
}
.env-banner {
  margin-left: 2em;
  font-weight: bold;
  padding: 2em 1em;
  text-align: center;
  display: inline-block;

}
.env-banner.test {
  background-color: rgba(255, 0, 0, 0.2);
  color: red;
  

}
.env-banner.prod {
  
}
.env-banner.dev {
  background-color: rgba(255, 0, 0, 0.2);
  color: red;
}

.line-hidden {
  opacity: .5;
  display: none;
}
.dash-cont {
  position: relative;
  left: 0;
  top: $top-nav-height;
  width: 100vw;
  min-height: calc(100vh - #{$top-nav-height} );
  padding: 3%;
  box-sizing: border-box;
}

.dash-bg {
  position: fixed;
  // height: 900px;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  left: 0;
  top: $top-nav-height;
  width: 100vw;
  min-height: calc(100vh - #{$top-nav-height} );
  padding: 3%;
  box-sizing: border-box;
}
.dash-bg.admin, .dash-cont.admin {
  top: $admin-top-nav-height;
  min-height: calc(100vh - #{$admin-top-nav-height} );
}

.dash-card {
  font-size: 16px;
  width: 8em;
  padding: 0em;
  height: 8em;
  background: white;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 0 .5em .5em rgba(0,0,0,.1);

  .bold {
    font-size: 1em;
  }
}


.full {
  width: 100%;
}

.full-spinner-cont {

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
  cursor: pointer;
  z-index: 10000;

  .modal-content {
    pointer-events: all;
    position: relative;
    background:
    white;
    width: 100%;
    max-width: 900px;
    top: 10%;
    top: 5vh;
    max-height: 90vh;
    overflow: auto;
    border-radius: 1rem;
  }


  
}

.global-success-cont {

  position: fixed;
  bottom: 3em;
  /* right: 0; */
  padding: 30px;
  z-index: 99999;
  pointer-events: none;
  left: 50%;
  transform: translate(-50%, 0);

  
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .global-success.opened.fading {
    opacity: 0;
    transform: scale(0);
    // transition: .5s all;
    transition: 3s transform linear, .2s opacity linear;
    // transition: s opacity;
  }

  .global-success:last-child {
    margin-bottom: 0;
  }

  .global-success.opened {
    // transform: scale(1);
    // opacity: 1;

    // transition: .25s all;
  }

  .global-success {
    // transform: scale(.75);
    // opacity: 0;

    pointer-events: all;
    // transition: 1s all;
    background: white;
    color: black;
    display: inline-block;
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: .75em;
    padding-right: 3em;
    border-radius: .5em;
    box-shadow: 0 .33em 1em rgba(0,0,0,.25);
    margin-bottom: 1em;
    min-width: 15em;
    // color: aq;
    font-weight: bold;
    // border: 3px solid mediumaquamarine;
    z-index: 999999999999;

    a {
      color: $yellow;
    }
    .i-cont {
      display: inline-flex;
      width: 2em;
      height: 2em;
      background: mediumaquamarine;
      color: white;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      margin-right: 1em;
    }
    i:hover {
      color: white;
    }
  }

}
.global-error-cont {
  position: fixed;
  top: 70px;
  right: 0;
  padding: 30px;
  z-index: 9999999999999;
  max-width: 800px;
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .global-error {
    background: $dangerRed;
    color: rgb(255, 222, 222);
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75em;
    padding-right: 1em;
    border-radius: .5em;
    box-shadow: 0 1em 3em rgba(0,0,0,.5);
    a {
      color: white;
    }
    i {
      color: white;
    }
    .button.ghost:hover {
      background: rgba(0,0,0,.5);
    }
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


.text-link {
  border-bottom: 1px solid rgba(0,0,0,.1);
  font-weight: bold;
}
.text-link:hover {
  border-bottom: 1px solid rgba(0,0,0,.5);
}

.mobile-only {
  @include desktop {
    display: none !important;
  }
}
.desktop-only {
  @include mobile {
    display: none !important;
  }
}

.code {
  background: rgba(0,0,0,.05);
  padding: 1rem;
  overflow: auto;
  margin: 1em 0;
  max-width: 100%;
  width: 100%;

}


.narrow-2 {
  width: 100%;
  max-width: 4440px;
  margin: auto;
  padding: 0 2%;

  width: calc(100% - 200px);
  // overflow: hidden;
  box-sizing: border-box;
}



nav.main.scrolled {
  box-shadow: 0 10px 20px rgba(0,0,0,.1);
  transition: box-shadow 1s;
}

nav.main.admin-main-nav {
  height: $admin-top-nav-height;

  .nav-inner {
    height: $admin-top-inner-nav-height;
  }
}
h1 {
  .medgray {
    font-weight: 300;
    padding-right: .33em;
    margin-right: .33em;
    border-right: 1px solid rgba(0,0,0,.1)
  }
}
nav.main {
  height: $top-nav-height;

  .company-logo {
    padding: 4px 0;
    margin-left: 1em;
    padding-left: 1em;
    margin-top: 4px;
    border-left: 1px solid rgba(0,0,0,.1);
  }

  .nav-inner {
    display: flex;
    height: $top-inner-nav-height;
    align-items: center;
    justify-content: center;
  }
  .view-as {
    height: 50px;
    background: black;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
  }
  // display: flex;
  
  
  position: fixed;
  width: 100vw;
  z-index: 2000;
  background: #f9f9f9;
  // box-shadow: 0 .1em .1em #00000021;
  box-shadow: none;
  // padding: 0 1em;
  // background: white;

  transition: box-shadow 1s;

  .ham-menu-cont {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .33s;
    background: rgba(0,0,0,.5);
  }
  .ham-menu-cont.opened {
    pointer-events: all;
    opacity: 1;
  }

  .ham-menu {
    position: absolute;
    right: -100%;
    top: 0;
    width: 95vw;
    background-color: $blue;
    // display: none;
    height: 100vh;
    transition: all .33s;
    z-index: 999999;
    opacity: 0;
    display: flex;
    justify-content: flex-end;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 1.5rem;
      margin-top: 4em;
      font-weight: 900;
      text-align: right;
      display: inline-flex;
      flex-direction: column;
      margin-right: 1em;

      li {
        border-radius: 99999px;
        transition: background .2s;
        margin-bottom: .5em;
        display: inline-block;
        
        a {
          color: white;
          display: inline-block;
          padding: .5em 1em;
          
        }
      }
      li:hover {
        background: $darkBlue;
      }
    }

    .ham-close {
      font-size: 1.25rem;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      border-radius: 50%;
      background: rgba(0,0,0,.05);
      right: 2rem;
      top: 1rem;
      position: absolute;
    }
  }

  .ham-menu.opened {
    // display: block;
    right: 0;
    // background-color: white;
    color: white;
    opacity: 1;
  }



  .ham-cont {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    padding-right: 1rem;

    i {
      font-size: 1.25rem;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      color: $darkBlue;
      border-radius: 50%;
      background: rgba(0,0,0,.05);
    }

    @include desktop {
      display: none;
    }
  }

 
  .logo-cont {

    svg, img {
      height: 2em;
      position: relative;
      // left: -16px;
      top: 3px;

      // @include mobile {
      //   height: 
      // }
    }

    path {
      // animation: logo-ani 5s infinite linear;
      // fill: black;
    }
  }
  .nav-links-cont {
    flex: 1;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mobile {
      display: none;
    }

    a.active.button.ghost:hover {
      background: $blue;
      // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
      box-shadow: none;
    
      i {
        color: white;
      }
    }

    a.active {
      i {
        color: $blue;
      }

      .button.ghost:hover {
        background: $blue;
        // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
        box-shadow: none;
      
        i {
          color: white;
        }
      }
    }
    a {
      i {
        color: rgba(0,0,0,.3);
      }
      i:hover {
        color: rgba(0,0,0,.4);
      }
    }


    a.text-link {
      padding: .66rem;
    }
    .button {
      margin-left: .5rem;
    }
  }
}
nav.main {

  .nav-submenu-cont {
    .nav-submenu-clicker {
      cursor: pointer;
      color: rgba(0,0,0,.3);
    }
    .nav-submenu-clicker:hover {
      color: rgba(0,0,0,.4);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .nav-submenu {
      background: white none repeat scroll 0% 0%;
      position: absolute;
      top: 50px;
      right: 0px;
      padding: 1rem;
      border-radius: 1rem;
      // border-top-right-radius: 0;
      box-shadow: 0 10px 20px rgba(0,0,0,.1);
      padding-right: 2rem;
    }
    .nav-submenu:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: white;
    }
  }
  .nav-submenu-cont.opened {
    .nav-submenu-clicker {
      color: $blue;
    }
  }



  .mobile-only {

    margin: 0 1rem;

    i {
      color: rgba(0,0,0,.3);
    }
    a.active {
      i {
        color: $blue;
      }
    }

    .mobile-nav-item {
      padding: .75rem;
      font-size: 1.25em;
      cursor: pointer;
    }

    .mobile-nav-left {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }

    .mobile-nav-logo-cont {
      img {
        height: 30px;
      }
    }
    .mobile-nav-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;

    }

    
  }
}


a {
  color: rgba(0,0,0,1);
  text-decoration: none;
}

.burger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
  height: $top-nav-height;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.5rem;
  color: $yellow;
  cursor: pointer;
  transition: all .2s;
}
.burger:hover {
  color: lighten($yellow, 20);
  transition: all .2s;
}

.bottom-menu-bg {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2999;
  background: rgba(0,0,0,.5);
  position: fixed;
  opacity: 0;
  transition: all .33s;
  pointer-events: none;
  height: 100%; 
  cursor: pointer;  

  @include desktop {
    display: none;
  }
}
.bottom-menu-bg.opened {
  opacity: 1;
  pointer-events: all;
}


nav.side-nav {

  // background: linear-gradient(to bottom, rgba(0,0,0,.1) 0%, rgba(0,0,0,0));
  // width: 200px;
  // position: fixed;
  // left: 0;
  // top: $top-nav-height;
  // bottom: 0;

}

.side-nav.opened {
  @include mobile {
    top: 40%;
  }
  
}

.mobile-center {
  @include mobile {
    text-align: center;
  }
}

i.pencil.note-present {
  background: rgba(0,0,0,.1);
  background: $yellow;
}

.full-height {
  height: 100%;
}

i.pencil {
  // color: rgba(0, 0, 0, 0.4);
  // //font-size: 12px;
  // padding: 4px;
  // //margin-right: 4px;
  // border-radius: 50%;
  // //margin-bottom: 2px;
  // position: absolute;
  // right: 0;
  // bottom: 0;

  color: rgba(0, 0, 0, 0.6);
  padding: 4px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -50%;
  font-size: .65rem;
}
i.pencil:hover {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: black;
}

.fullscreen-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  // background: rgba(0,0,0,.33);
  background: white;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;

}
nav.side-nav.admin-side-nav {
  @include desktop {
    padding-top: calc(#{$admin-top-nav-height} / 4);
    height: calc(100vh - #{$admin-top-nav-height});
    top: $admin-top-nav-height;
  }
}
nav.side-nav {

  @include desktop {
    background: linear-gradient(to bottom, rgb(229, 229, 229) 0%, rgb(255, 255, 255));
    // background: linear-gradient(to bottom, rgba(0,0,0,.1) 0%, rgba(0,0,0,0));
    width: $side-nav-width;
    padding-top: calc(#{$top-nav-height} / 4);
    height: calc(100vh - #{$top-nav-height});
    overflow-y: auto;
    display: inline-block;
    position: fixed;
    top: $top-nav-height;
    z-index: 1;
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      // padding: 0 .5em;

      .item-header {
        font-weight: bold;
        color: rgba(0,0,0,.33);
        margin: .5em 0;
        margin-top: 1em;
      }

      li.apex {
        padding: .75em 1em;
        margin-bottom:.75em;
        margin-top: .5em;
      }

      li {
        
        a.active {
          // font-weight: bold;
          // background: rgba(0,0,0,.05);
          background: rgba(0,0,0,.05);
        }

        a, div.title {
          display: flex;
          padding: .75em;
          padding-left: 1em;
          // color: $darkBlue;
          align-items: center;

          span {
            flex: 1;
          }
          i {
            width: 2em;
            
          }
        }

        div.title {
          
          font-weight:bold;
          color: rgba(0,0,0,.5);

          padding-left: 0;
          padding-right: 0;
          // margin-bottom: .5em;
          // padding: .75em 0;
        }
      }
      li:not(.apex):hover {
        background: rgba(0,0,0,.1);
      }
    }
  }

  @include mobile {

    div {
      // padding-bottom: 2em;
    }


    // bottom: 60px;
    left: 0;
    right: 0;
    position: fixed;
    background: #fbfbfb;
    color: white;
    padding: 1rem;
    z-index: 3000;
    top: 100%;
    transition: all .2s;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    box-shadow: 0rem 0 2rem rgba(0,0,0,.25);
    overflow-y: auto;
    height: 100%;
  
    @include desktop {
      display: none;
    }
  
    ul {
      margin: 0;
      padding: 0;
  
      list-style: none;
  
      a.active {
        font-weight: bold;
        background: rgba(0,0,0,.05);
      }
  
      a, div.title {
        display: flex;
        padding: .75em;
        padding-left: 1em;
        align-items: center;
        color: $darkBlue;
        i {
          width: 2em;
  
        }
      }
  
      li {
        // padding: 1rem;
        // border-bottom: 1px solid rgba(0,0,0,.075);
      }
      li:last-child {
        border: none;
      }
    }
  
  }

}
.force-no-margin {
  margin: 0 !important;
}
.no-margin {
  margin: 0;
}
h1.no-margin-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
h1.no-margin-bottom {
  margin-bottom: 0 !important;
}
h1.no-margin-top {
  margin-top: 0 !important;
}
h2.no-margin-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
h2.no-margin-bottom {
  margin-bottom: 0 !important;
}
h2.no-margin-top {
  margin-top: 0 !important;
}

main.admin-main {
  padding-top: $admin-top-nav-height;
}
main {
  min-height: 100vh;
  padding-top: $top-nav-height;
  padding-bottom: 200px;
}

.content-cont {
  flex: 1;
  

  .content {
    // margin-left: $side-nav-width;
    // flex: 1;
    // padding: 0 2em;
    // padding-top: 1em;

    // width: 100%;

    h1 {
      margin-top: 1em;
    }

    .narrow-content {
      padding: 0 2em;
    }
  }

  .content.margin {
    margin-left: $side-nav-width;

    @include mobile {
      margin-left: 0;
    }
  }

  
}


input.small, select.small {
  min-width: 5em !important;
  width: 5em !important;
}

input[type=text].pledge {
  width: 3em;
  font-size: 1em;
  padding: .25em;
  text-align: center;
}

.loading-input-dummy {
  border: 2px solid rgba(0,0,0,.1);
  // padding: 1em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
  min-width: $select-min-width;
  border-radius: $borderRadius;
  font-size: .7rem;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;


  .spinner {
    font-size: .55rem;
    margin-right: .5em;
    // position: absolute;
    // top:0;
  }
}



.editable-cont, input[type=date], input[type=number], input[type=text], textarea, input[type=password], .DayPickerInput input, select, .dummy-input-bg, .dummy-select {
  border: 2px solid rgba(0,0,0,.1);
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
  min-width: $select-min-width;
  border-radius: $borderRadius;
  font-size: .7rem;
  font-family: Arial, Helvetica, sans-serif;
}
input[type=date] {
  min-width: 9rem !important;
}

textarea {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}
.dummy-input  {
  background: none;
  border: none;
  height: 34px;
  padding-top: 9px;
  padding-bottom: 9px;
}



.hour-input {
  min-width: auto !important;
  width: 5em !important;
  max-width: 5em !important;
}

.absolute {
  position: absolute;
}

.editable-cont.loading {
  background: rgba(0,0,0,.025);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    font-size: .5em;
    position: absolute;
  }
  // animation: load9 1s infinite ease-in-out;
}


@keyframes load9 {
  0%{background: rgba(0,0,0,.1);}
  50%{background: rgba(0,0,0,.01);}
}

.button.ghost:focus, .button:focus, input[type=number]:focus, input[type=text]:focus, textarea:focus, input[type=password]:focus, .DayPickerInput input:focus, select:focus {
  border: 2px solid $yellow;
}

.loading-gradient {
  background: rgba(0,0,0,.05);
  background: linear-gradient(90deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,1) 100%);


  animation: load-gradient 1s infinite linear;
}

.section.loading-gradient  {

  background: rgba(0,0,0,.05);
  background: linear-gradient(90deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,1) 100%);


  animation: load-gradient 1s infinite linear;
}


@keyframes load-gradient {
  0% {
      background: rgba(0,0,0,.05);
      // background: linear-gradient(90deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,1) 100%);
    
  }

  50% {
    background: rgba(0,0,0,.15);
    // background: linear-gradient(90deg, rgba(0,0,0,0.05) 50%, rgba(0,0,0,1) 100%);
  }
  
  100% {
    // transform: rotate(360deg);

    background: rgba(0,0,0,.05);
    // background: linear-gradient(90deg, rgba(0,0,0,0.05) 75%, rgba(0,0,0,1) 100%);
  }
}

.section.better {
  padding: 1em;
  background: white;
  box-sizing: border-box;
  border-radius: 1em;
  /* min-width: 100%; */
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  min-width: 100%;
}

.section.scroll {
  overflow-x: auto;
}
.section.wide {
 
}
.section {
  padding: 1em;
  background: white;
  border-radius: 1em;
  background: rgba(0,0,0,.05);

  // overflow-x: auto;
}

option {
  border:none;
  outline:none;
}

fieldset {
  border:none;
  outline: none;
  // padding: 1em;
  // // background: rgba(0,0,0,.05);
  // margin-bottom: 1em;
}

input[type="submit"], button[type=submit] {
  font-size: 1em;
}

.field-error {
  color: $red;
  font-weight: bold;
  padding: 1em 0;
}
.has-errors {
  border-color: lighten($red, 30) !important;
}
.has-errors:focus {
  border-color: $red !important;
}


form {
  position: relative;
}


select, .dummy-select  {
  width: 15em;
  white-space: nowrap;
  overflow: hidden;
}

select.wrap, .dummy-select.wrap  {
  // width: 30em;
  // white-space: nowrap;
  //white-space: normal;
}

select.wide, .dummy-select.wide  {
  width: 25em;
  // white-space: nowrap;
}

select.full, .dummy-select.full  {
  width: 100%;
  min-width: 100%;
  white-space: nowrap;
}

select.auto-width, .dummy-select.auto-width  {
  width: auto;
  min-width: auto;
  max-width: 100%;
  white-space: nowrap;
}
// select:disabled {
//   opacity: .7;
//   background: rgba(0,0,0,.1);
//   color: rgba(0,0,0,.8);

// }

.error-cont {
  color: red;
  font-weight: bold;
  line-height: 1;
  font-size: .85rem;

  ul {
    margin: 0;
    padding: 0;
    padding-left: 2em;

    li {
      padding: 0;
      margin: 0;
    }
  }
}

.hiddenx {
  td {
    background: black !important;
    color: white;
  }
}
.pink {
  td {
    background: pink !important;
    color: white;
  }
}
.pink.hiddenx {
  td {
    background: darkred !important;
    color: white;
  }
}

label.error-label {
  .label-text {
    color: red;
  }

  input, input:focus, select, select:focus {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.247);
  }
}


.yellow {
  color: $yellow;
}
.green {
  color: green;
}

.icon-button {
  cursor: pointer;
  
}
.budget-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

i.camera.present {
  background: $yellow;
  color: black;
  border: 2px solid rgba(0,0,0,.33);
}
i.camera.present:hover {
  background: lighten($yellow, 10);;
  color: black;

}
i.camera {
  color: rgba(0,0,0,.33);
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background: lime;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
i:hover {
  // color: black;
}
i.no-hover:hover {
  color: inherit;

}

.button.circle {
  padding: 0;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  // background: $sage;
  // color: #f6c346;
  background: lighten($sage, 25);
  color: rgba(255,255,255,1);
  border: none;
  transition: all .2s;
}
.button.circle:hover {
  background: $yellow;
  // color: black;

}

.admin-fixing-toast {
  z-index: 100;
  pointer-events: all;
  position: fixed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;

  padding: .5em 1em;
  color: gray;

  border-radius: .5em;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 4em;
  box-shadow: 0 .5em 1em rgba(0, 0, 0, 0.5);
  border:3px solid $blue;

  .admin-badge {
    font-weight: 900;
    color: $blue;
    margin-right: 1em;
  }
}
.blue {
  color: $blue;
}
select.admin-editable {
  border-color:$blue;
  padding:.4em;
  width: auto;
  min-width: auto;
  color: $darkBlue2;


  option {
    font-weight: bold;
    color: $darkBlue2;
  }
}


.button.admin-button.admin-button-outline {
  color: $blue;
  border-color: $blue;
  background: none;
}
.button.admin-button{
  color: black;
  border-color: $blue;
  background: $blue;


  .admin-button-badge {
    opacity: .75;
    font-weight: 900;
    font-size: .8em;
    margin-right: .5em;
    padding-top: .2em;
  }
}
.button.admin-button:hover {
  background: lighten($blue, 30);


  i {
    color: black;
  }
}


.button.ghost {
  background: none;
  transition: all 200ms;
  color: rgba(0,0,0,.75);
  border: none;
  box-shadow: none;
}
.button.ghost:hover {
  // background: $yellow;
  background: lighten($yellow, 20);
  background: $eucalyptus;
  // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
  

  i {
    color: black;
  }
}

table.basic.compact {

  tr.blue {
    th {
      background-color: lightblue;
    }
  }

  tr.red {
    th {
      background-color: lightpink;
    }
  }

  tr.totals {
    th, td {
      padding-bottom: 1.5em;
      padding-top: 1.5em;
    }
    
  }

  

  tr.highlightable {
    // cursor: pointer;
    td {
      transition: .05s all;
    }

    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }


  tr.highlightable:hover {
    td {
      background: lighten($yellow, 30) !important;
    }
  }
  tr.highlightable.highlighted:hover {
    td {
      background: lighten($yellow, 25) !important;
    }
  }

  tr.highlighted {
    td {
      background: lighten($yellow, 20) !important;
    }
  }



  
  
  tr {
    td, th {
      font-size: 12px;
      padding: 2px;
      // border-right: 1px solid rgba(0,0,0,.02);


      input, .calc, select, option {
        padding: .33em;
        // font-size: .7rem;
      }
      
    }
    th {
      background-color: rgba(0,0,0,.075);
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
    // td.border-right-2 {
    //   border-right: 2px solid black;
    // }
    // td.border-bottom-2 {
    //   border-bottom: 2px solid black;
    // }
    // td.border-left-2 {
    //   border-left: 2px solid black;
    // }
    // td.border-top-2 {
    //   border-top: 2px solid black;
    // }
    // td.summary {
    //   border: 2px solid black;
    // }
    th {
      font-size: 11px;
      color: black;
      font-weight: 600;
      padding: .5em;

      .small-text {
        font-size: 9px;
      }
    }
    td {
      input {
        padding: .33em;
      }
      input.auto-width {
        min-width: auto;
      }
    }

    .width-phase {
      width: 5rem !important;
      max-width: 5rem !important;
    }
    .width-milestone {
      width: $select-min-width !important;
      max-width: $select-min-width !important;
    }
    .width-taskname {
      width: $select-min-width !important;
      max-width: $select-min-width !important;
    }


    .width-hours {
      width: 5rem !important;
      max-width: 5rem !important;
    }
    .width-date {
      width: 9rem !important;
      max-width: 9rem !important;
      box-sizing: content-box;

      input[type=date] {
        padding: 2px !important;
      }
    }
    .width-money {
      width: 4rem !important;
      max-width: 4rem !important;
      min-width: 4rem !important;
    }
  }
}

table.basic table.inner-table {
  border-collapse: collapse;
  table-layout: fixed;
  
  // width: 100%;
  tr {
    td {
      padding: 4px;
      // border: 1px solid black;
      // width: auto !important;
      background: none;

      .calc {
        background: none;
      }
      input:not(.react-date-picker__inputGroup__input) {
        // width: 100% !important;
      }
      input, .calc {
        // width: 100%; 
        margin: 0;
        // !important;
        min-width: 0;
        box-sizing: border-box;
      }
    }
  }
}

.removed-row {
  opacity: .25;
}

table.basic.budget-worksheet.wide {
  // min-width: 1780px;
}
table.basic.budget-worksheet {
  table-layout: fixed;
  width: 100%;
  // min-width:1000px;

  .calc, input:disabled, .disabled-input {
    border: 2px solid rgba(0,0,0,.1);
    
    // rgb(224, 224, 224);
    border-radius: 4px;
    // width: 100%;

    // width: 5em;
    background: none;
    //  rgb(224, 224, 224);
    display: flex;
    align-items: center;
  }



  tr {

    th, td {
      // border: 1px solid black;
      padding: 4px;
      box-sizing: border-box;
    }

    

    th {
      font-size: 10px;
      color: black;
      vertical-align: bottom;
      // padding: 4px;

      
    }
    td {
      vertical-align: top;
      // padding: .33em;
      input, .calc, select {
        padding: .33em;
        font-size: .7rem;
      }
    }

    .width-phase {
      width: 5rem !important;
      max-width: 5rem !important;
    }
    .width-milestone {
      width: $select-min-width !important;
      max-width: $select-min-width !important;
    }
    .width-taskname {
      width: $select-min-width !important;
      max-width: $select-min-width !important;
    }


    .width-hours {
      width: 5rem !important;
      max-width: 5rem !important;
    }
    .width-date {
      width: 9rem !important;
      max-width: 9rem !important;

      box-sizing: content-box;

      input[type=date] {
        padding: 2px !important;
      }
    }
    .width-money {
      width: 6rem !important;
      max-width: 6rem !important;
    }
  }
}

select {
  
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  background: white;
  // padding-right: 2em;

  // option {
  //   padding-right: 2em;
  // }
}

.input-icon {
  font-size: .8em;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
}
table.vertical {
  width: 100%;
  border-collapse: collapse;
  
  .calc, input:disabled, .disabled-input {

    // background: none;
    // rgb(229, 229, 229);
    // border: 2px solid rgb(229, 229, 229);
    border-radius: 4px;
    width: 100%;
    font-size: .7rem;
    // display: flex;
    align-items: center;
  }
  input[type=radio]:disabled, input[type=checkbox]:disabled {
    display: unset;
    width: unset;
  }

  select, input, .calc {
    padding: .33em;
  }
  

  tr:last-of-type {
    td, th {
       border-bottom: none;
    }
  }
  tr.gray-bg {
    td, th {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  tr {
    th, td {
      padding: .1em;
      font-size: .8em;
      border-bottom: 1px solid rgba(0,0,0,.075);
    }
   
    th {
      // color: rgba(0,0,0,.5);
      // text-transform: uppercase;
      font-size: .8em;
      font-weight: bold;
      text-align: right;
      padding: .2em;
      white-space: nowrap;
      padding-right: 1em;
      width: 1%;
      // border-bottom: 2px solid rgba(0,0,0,.05);
    }

    td:last-of-type {
      // padding-right: 1em;
    }
    td {
      padding: .2em;
      font-weight: normal;
      


      
    }

  }
  tr:nth-child(2n) {
    td, th {
      // background: rgba(0,0,0,.025);
    }
  }

}

table.basic.table-loading {
  opacity: .33;
  pointer-events: none;
  transition: .2s opacity;

  animation: load-gradient 1s infinite linear;
}

table.basic {
  opacity: 1;
  transition: .2s opacity;
  width: 100%;
  border-collapse: collapse;
  font-size: .9rem;

  tr.align-top {
    td {
      vertical-align: top;
    }
  }

  tr.selectable {

    td {
      transition: .1s all;
    }

    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  tr.selected {
    td {
      background: lighten($yellow, 15) !important;
    }

    
  }

  tr.selectable {
    cursor: pointer;

    td {
      background: white;
    }
  }
  tr.selectable:hover {
    td {
      background: lighten($yellow, 25) !important;
    }
  }
  tr.selectable.selected:hover {
    td {
      // background: lighten($yellow, 5) !important;
    }
  }

  tr.total {
    th {
      // background: rgba(0,0,0,.075);
      border-bottom: none;
      border-top: 4px solid rgba(0, 0, 0, 0.05);
      // 2px solid rgba(0,0,0,.2);
      
      color: black;
    }
  }

  tr {
    th {
      color: rgba(0,0,0,.3);
      text-transform: uppercase;
      font-size: .9em;
      font-weight: bold;
      text-align: left;
      padding: .5rem;
      border-bottom: 2px solid rgba(0,0,0,.05);
    }
    th.center-text, th.center, th.text-center {
      text-align: center;
    }
    th.right-text, th.text-right, th.right {
      text-align: right;
    }
    td {
      padding: .5rem;
    }
    td.fit, th.fit {
      width: 1%;
    }

    
  }
  tr:nth-child(2n) {
    td {
      background: rgba(0,0,0,.025);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.padding-1 {
  padding: 1rem;
}
.padding-2 {
  padding: 2rem;
}
.padding-3 {
  padding: 3rem;
}
.padding-4 {
  padding: 4rem;
}

.padding-top-1 {
  padding-top: 1rem;
}
.padding-top-2 {
  padding-top: 2rem;
}
.padding-top-3 {
  padding-top: 3rem;
}
.padding-top-4 {
  padding-top: 4rem;
}
.padding-top-5 {
  padding-top: 5rem;
}


.padding-bottom-1 {
  padding-bottom: 1rem;
}
.padding-bottom-2 {
  padding-bottom: 2rem;
}
.padding-bottom-3 {
  padding-bottom: 3rem;
}
.padding-bottom-4 {
  padding-bottom: 4rem;
}
.padding-bottom-5 {
  padding-bottom: 5rem;
}
.padding-left-1 {
  padding-left: 1rem;
}
.padding-left-2 {
  padding-left: 2rem;
}
.padding-left-3 {
  padding-left: 3rem;
}
.padding-left-4 {
  padding-left: 4rem;
}
.padding-left-5 {
  padding-left: 5rem;
}


.margin-1 {
  margin: 1rem;
}
.margin-2 {
  margin: 2rem;
}
.margin-3 {
  margin: 3rem;
}
.margin-4 {
  margin: 4rem;
}
.margin-vertical-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin-vertical-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.margin-left-05, .margin-left-half {
  margin-left: .5rem;
}
.margin-left-1 {
  margin-left: 1rem;
}
.margin-left-2 {
  margin-left: 2rem;
}
.margin-right-4px {
  margin-right: 4px
}
.margin-right-half, .margin-right-05 {
  margin-right: .5rem;
}
.margin-right-1 {
  margin-right: 1rem;
}
.margin-right-2 {
  margin-right: 2rem;
}
.margin-right-3 {
  margin-right: 3rem;
}
.margin-right-4 {
  margin-right: 4rem;
}
.padding-right-half, .padding-right-05 {
  padding-right: 0.5rem;
}
.padding-right-1 {
  padding-right: 1rem;
}
.padding-right-2 {
  padding-right: 2rem;
}
.padding-right-3 {
  padding-right: 3rem;
}
.margin-bottom-2px {
  margin-bottom: 2px;
}
.margin-bottom-half {
  margin-bottom: .5rem;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 2rem;
}
.margin-bottom-3 {
  margin-bottom: 3rem;
}
.margin-bottom-4 {
  margin-bottom: 4rem;
}
.margin-bottom-5 {
  margin-bottom: 5rem;
}
.margin-top-0 {
  margin-top: 0rem;
}
.margin-top-2px {
  margin-top: 2px;
}
.margin-top-4px {
  margin-top: 4px;
}
.margin-top-025, .margin-top-quarter {
  margin-top: .25rem;
}
.margin-top-05, .margin-top-half {
  margin-top: .5rem;
}
.margin-top-1 {
  margin-top: 1rem;
}
.margin-top-2 {
  margin-top: 2rem;
}
.margin-top-3 {
  margin-top: 3rem;
}
.margin-top-4 {
  margin-top: 4rem;
}
.margin-top-5 {
  margin-top: 5rem;
}

.flex-cont {
  display: flex;
}
.flex-cont.column {
  flex-direction: column;
}

.flex-column {
  flex-direction: column;
}
.flex-cont.row {
  flex-direction: row;
}

.flex-row {
  flex-direction: row;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-baseline {
  align-items: baseline;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 2;
}
.flex-4 {
  flex: 2;
}

.display-none {
  display: none !important;
}


.actually-invisible {
  opacity: 0;
  // width: 0;
  height: 0;
  pointer-events: none;
  display: none;
}

.input-font {
  font-size: 11.2px;
}

input.small, select.small {
  min-width: 5em !important;
  width: 5em !important;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}
.red {
  color: $dangerRed;
}
.gray {
  color: gray;
}
.light-gray {
  color: rgba(0,0,0,.2);
}
.bold {
  font-weight: bold;
}
.normal {
  font-weight: normal;
}

.font-05 {
  font-size: .5em;
}
.font-07 {
  font-size: .7em;
}
.font-075 {
  font-size: .75em;
}
.font-08 {
  font-size: .8em;
}
.font-09 {
  font-size: .9em;
}
.font-1 {
  font-size: 1em;
}
.font-1-15 {
  font-size: 1.15em;
}
.font-1-25 {
  font-size: 1.25em;
}
.font-1-5 {
  font-size: 1.5em;
}
.font-1-75 {
  font-size: 1.75em;
}
.font-2 {
  font-size: 2em;
}
.font-3 {
  font-size: 3em;
}
.font-4 {
  font-size: 4em;
}

.font-5 {
  font-size: 5em;
}
.font-7-5 {
  font-size: 7.5em !important;
}
.font-10 {
  font-size: 10em;
}
.font-15 {
  font-size: 15em;
}
.font-20 {
  font-size: 20em;
}
.font-25 {
  font-size: 25em;
}
.font-30 {
  font-size: 30em;
}


.spinner-text {
  color: $yellow;
  font-size: .75em;
}
.spinner {
  // margin: 2em;
  // font-size: 1em;
  // border-image: linear-gradient(to right, #3acfd5 0%, #3a4ed5 100%) 1;
  // background: linear-gradient($yellow, $yellow) padding-box,
  // linear-gradient(90deg, rgb(90, 90, 90) 0%, rgba(0,0,0,0) 78%, rgba(0,0,0,0) 100%)  border-box;
  
  // linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,0)) border-box;
  position: relative;
  // text-indent: -9999em;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  // border-left: .1em solid rgba($yellow, 1);
  // border-top: .2em solid rgba($yellow, 1);
  // border-right: .2em solid rgba($yellow, 1);
  // border-bottom: .2em solid rgba(0,0,0, 0);

  border-left: .15em solid rgba(94, 94, 94, 1);
  border-top: .15em solid rgba(94, 94, 94, 1);
  border-right: .15em solid rgba(94, 94, 94, 1);
  border-bottom: .15em solid rgba(0,0,0,0);


  transform: translateZ(0);
  animation: load8 .4s infinite linear;
  display: inline-block;
}

.full-screen-spinner-cont {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,.5);
  z-index:9999999;
  
  opacity: 0;
  pointer-events: none;
  transition: all .25s;
}
.full-screen-spinner-cont.entered {
  -webkit-backdrop-filter: blur(2px); /* Add this line first, it fixes blur for Safari*/
  backdrop-filter: blur(2px);  /* This line activates blur*/
  opacity: 1;
  pointer-events: all;
  transition: all .25s;
}

.spinner-cont-cool {
  font-size: 1.5em;
  background: white;
  padding: 1.5em;
  border-radius: 1em;
  position: fixed;
  pointer-events: none;
  box-shadow: 0 0 1em rgba(0,0,0,0);
  // animation: enter 1s;
  z-index: 99999999;
  opacity: 0;
  transition: all .25s;

  top: 40%;
  left: 50%;
  transform: translate(-40%, -50%) scale(.9);
}
.spinner-cont-cool.entered {
  opacity: 1;
  transform: translate(-40%, -50%)  scale(1);
  transition: all .25s;
  box-shadow: 0 .5em 1em rgba(0,0,0,.25);
}

@keyframes enter {
  0% {
    transform: scale(0);
    
  }
  
  100% {
    transform: scale(1);
  }
}


table.basic.expense-report {
  tr {
    td {
      vertical-align: top;
    }
  }
}

.button-spinner, .absolute.spinner {
  position: absolute;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
    
  }
  
  100% {
    transform: rotate(360deg);
  }
}

.view-as-banner {
  padding: .5em;
  background: rgba(0,0,0,.75);
  color: white;
  margin-right: 1em;
  
  font-size: .75em;
  border-radius: 4px;

  div {
    font-weight: 900;
    margin-left: .7em;
  }
}

.check-boxes {
  display: flex;
  align-items: center;
  border-radius: .5rem;
  display: inline-flex;
  // border: 1px solid darken(white, 20%);
  overflow: hidden;
  transition: all .2s;
  background: rgba(0,0,0,.05);

  .check-box-label {
    font-weight: bold;
    color: rgba(0,0,0,.5);
  }
}


.check-box-cont {
  cursor: pointer;
  padding: .5em 1em;
  // margin-right: .5em;

  .unread-count {
    padding: 2px 6px;
    font-size: 11px;
    font-weight: 900;
    border-radius: 99999px;
    color: white;
    background: rgba(0,0,0,.75);
    // margin-right: .7em;
    // line-height: 1;

  }
}
.check-box-cont:not(.disabled):hover {

  // background: rgba(0,0,0,.05);
  // border-radius: .25em;

  .check-box-label {
    // color: rgba(0,0,0,.75);
  }

  .check-box:not(.disabled) {
    // outline: 2px solid rgba(0,0,0,.8);

    .check {
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
    }
  }
}



.check-box {

  font-size: 10px; 
  width: 1em;
  height: 1em;
  padding: 2px;
  background: white;
  outline: 2px solid rgba(0,0,0,.2);
  cursor: pointer;

  min-width: 1em;
  max-width: 1em;
  min-height: 1em;
  max-height: 1em;

  .check {
    display: none;
  }
}
.check-box:not(.disabled):hover {
  // outline: 2px solid rgba(0,0,0,.8);
  .check {
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
  }
}

.check-box.selected {

  .check {
    display: block;
    width: 100%;
    height: 100%;
    background: black;
  }
}


.check-box.radio {
  border-radius: 50%;

  .check {
    border-radius: 50%;
  }
}

.check-box-cont.disabled {
  pointer-events: none;
  cursor: pointer !important;

  .check {

  }
}

.check-box.disabled {
  pointer-events: none;
  cursor: pointer !important;

  .check {

  }
}

.check-box.disabled:hover {
  outline: 2px solid rgba(0,0,0,.2);
  

}


.tooltip-cont {
  position: relative;
  display: inline-flex;

  .tooltip {
    display: none;
    top: 100%;
    left: 0;
    background:white;
    position: absolute;
    box-shadow: 0 4px 8px rgba(0,0,0,.25);
    z-index: 1000;
    min-width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      max-height: 20em;
      overflow-y: auto;

      li {
        a {
          padding: .75em 2em;
          padding-left: .75em;
          display: block;
          cursor: pointer;
        }
        
      }

      li:hover {
        background-color: rgba(0,0,0,.05);
      }

    }

  }
  .tooltip.open {
    display: block;
  }
}
.chevron-cont {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.chevron-cont:hover {
  // background-color: rgba(0,0,0,.15);
  background-color: lighten($yellow, 20%);
}
.ellipsis-v-cont {
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.ellipsis-v-cont:hover {
  // background-color: rgba(0,0,0,.15);
  background-color: lighten($yellow, 20%);
}

label {
  position: relative;
  display: block;

  .label-text {
    font-weight: bold;
    color: #606060;
    font-size:.8em;
    text-transform: uppercase;
  }

  // margin-bottom: 1em;
  // display: flex;
  // flex-direction: row;
}


.sub-nav {
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 2px solid rgba(0,0,0,.1);
  margin-bottom: 1em;

  li {
    margin-right: 1em;
    

    a {
      display: inline-block;
      font-size: 1em;
      padding: .5em;
      position: relative;
      top: 2px;
      color: rgba(0,0,0,.5);
      border-bottom: 2px solid transparent;
      position: relative;
      line-height: 1;

      .unread-count {
        padding: 2px 6px;
        font-size: 12px;
        font-weight: 900;
        border-radius: 99999px;
        color: white;
        background: rgba(0,0,0,.33);
        margin-right: .5em;
        line-height: 1;

      }

      .pulser {
        width: 7px;
        height: 7px;
        background: red;
        position: absolute;
        right: 0;
        top: 7px;
        border-radius: 50%;
      }
    }
    a.active {
      font-weight: normal;
      border-bottom: 2px solid #000;
      color: black;

      .unread-count {
        background: rgba(0,0,0,.66);
      }
    }
    a:hover {
      border-bottom: 2px solid rgba(0,0,0,.25);
      color: rgba(0,0,0,.65);
    }
  
  }
}

.hidden {
  display: none;
}

table.basic.no-stripes {
  tr {
    td {
      background: none;
    }
  }
  tr:nth-child(2n) {
    td {
      background: none;
    }
    td.hour {
      background: none;
    }
  }
  tr:hover:not(.selected) {
    td {
      background: none;
    }
  } 
}
.relative {
  position: relative;
}
.status-badge-cont {
  
  .status-badge-shell {
    width: 8em;
  }

  .status-badge {
    font-weight: bold;
    padding: .5em;
    color: rgba(255,255,255,.95);
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .status-badge.budget {
    background-color: transparentize(black ,.9);
    color: black;
  }

  .status-badge.budget.returned {
    background-color: transparentize($dangerRed ,.8);
    color: $dangerRed;
  }
  .status-badge.budget.approved {
    background-color: transparentize($successGreen ,.8);
    color: $successGreen;
  }

  .status-badge.budget.pending {
    background-color: transparentize($yellow ,.8);
    color: darken($yellow, 15);
  }
  .status-badge.budget.new {
    background-color: transparentize(black ,.9);
    color: gray;
  }
  
}

.section.danger {
  background-color: transparentize($dangerRed ,.8);
  border-color: transparentize($dangerRed ,.8);
  color: $dangerRed;
}
.status-badge-cont.posted {
  .status-badge {
    background: forestgreen;
  }
  .status-message {
    color: forestgreen;
  }
}
.status-badge-cont.approved  {
  .status-badge {
    // background: rgba(34, 139, 34, 0.455);
    background: rgba(34, 139, 34, 0.1);
    color: forestgreen;
  }
  .status-message {
    color: forestgreen;
  }
}
.status-badge-cont.posting.failed  {
  .status-badge {
    // background: rgba(34, 139, 34, 0.455);
    background: rgba(0, 0, 0, 0.1);
    color: gray;
  }
  .status-message {
    color: gray;
  }
}

.status-badge-cont.returned {
  .status-badge {
    background: darkred;
  }
  .status-message {
    color: darkred;
  }
}
.status-badge-cont.draft, .status-badge-cont.created {
  .status-badge {
    background: rgba(0,0,0,.33);
    // color: black;
  }
  .status-message {
    color: darkred;
  }
}
.status-badge-cont.submitted {
  .status-badge {
    background: rgba(0,0,0,.77);
  }
  .status-message {
    color: darkred;
  }
}




.block-badge {
  font-weight: bold;
  font-size: 8px;
  padding: 4px;
  margin-top: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.block-badge.danger {
  background: darkred;
  color: white;

  // font-size: 1em;
}
.block-badge.success {
  background: forestgreen;
  color: white;
  // font-size: 1em;
}

input.number-input {
  width: 5em;
}

.entry-status {
  width: 8em;
  align-self: center;
}
.entry-status.returned {
  background: darkred;
  color: white;
}

.pointer {
  cursor: pointer;
}


.badge {
  background: black;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: $yellow;
  border-radius: 50%;


  span {

  }
}

.cool-row {
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,.1);
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 8px;
}

.entry-cont {

  font-size: 12px;

  .editable-cont, input[type=number], input[type="text"], textarea, input[type="password"], .DayPickerInput input, select, .dummy-input-bg, .dummy-select {
    padding: .33em;
  }

  .dummy-input {
    height: auto;
    padding: 0;
    margin: 0;
  }

  .error-cont {
    margin: 0;
    padding: 0;

  }
  .status-badge-cont {
    align-items: center;
    margin-top: .75rem;

    .status-badge {
      padding: 2px;
    }
  }

}

.entry-button {
  margin-top: .95em;
  // margin-left: 1em;
}
.entry-cont.new {
  // background: rgba(0,0,0,.05);
  padding: 8px 0;
  border-radius: 8px;
  border: 2px solid $yellow;

  .status-badge {
    background: $yellow;
  }


  // padding-top: 1em;
  // margin-top: 1em;
  // border-top: 3px solid rgba(0,0,0,.25);

}
.entry-cont.submitted {
  opacity: .9;
}

.row {
  // padding: 1em;
  margin-bottom: .5em;
  // background: white;
  // border-radius: 5px;
}


.button, input[type=submit], button {
  white-space: nowrap;
  background: $sage;
  border-radius: 4px;
  // border: .15em solid black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .6em 1.5em;
  color: rgba(0,0,0,.75);
  color: rgba(255,255,255,1);
  transition: all .2s;
  // line-height: 1;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  padding-left: 1.4em;
  // border: none;
  border: 2px solid $yellow;
  // font-family: "Tofino Wide", sans-serif;
}

.button:hover, input[type=submit]:hover {
  background-color: darken($yellow, 20);
  // transform: scale(1.025);
  // color: black;
  // box-shadow: .2em .2em .2em black;
}
button, input[type=submit] {
  font-size: 1rem;
}
i.disabled-icon {
  cursor: not-allowed;
  pointer-events: none;

  opacity: 0;
}

.button.disabled, 
input[type=date]:disabled, 
input[type=submit].disabled, 
button:disabled, 
button[type=submit]:disabled, 
input[type=number]:disabled, 
input[type=text]:disabled, 
select.disabled, select:disabled, 
textarea:disabled, 
input[type=date].disabled-input, 
input[type=submit].disabled-input, 
button.disabled-input, 
button[type=submit].disabled-input, 
input[type=number].disabled-input, 
input[type=text].disabled-input,
select.disabled-input, 
textarea.disabled-input {
  pointer-events: none !important;
  cursor: not-allowed;
  opacity: 1;
  background: rgba(0,0,0,.025);
  color: rgba(0,0,0,1);
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.02);
}
.button.disabled.ghost {
  border: none;
}

.disabled-table {
  pointer-events: none !important;
}


// .disabled-input {
//   pointer-events: none !important;
//   opacity: .75 !important;
//   cursor: not-allowed !important;
// }

.entry-review-row.disabled, .container-disabled {
  opacity: .33;
  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }
}
.disabled {
  //pointer-events: none !important;
  opacity: .5 !important;
  cursor: not-allowed !important;
}
.invisible {
  pointer-events: none !important;
  opacity: .1 !important;
  cursor: pointer !important;
}

select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.selector-cont {
  border-radius: .5rem;
  display: inline-flex;
  border: 1px solid darken(white, 20%);
  overflow: hidden;
  transition: all .2s;
  background: rgba(0,0,0,.1);

  .selector {
    font-weight: bold;
    padding: .5em 1em;
    cursor: pointer;
    // color: rgba(0,0,0,0.5);
    opacity: .5;
    border-radius: .5em;
    margin-right: 4px;
    // display: inline-block;


    .unread-count {
      padding: 2px 6px;
      font-size: 11px;
      font-weight: 900;
      border-radius: 99999px;
      color: white;
      background: rgba(0,0,0,.75);
      // margin-right: .7em;
      // line-height: 1;

    }
  }
  

  .selector:last-of-type {
    margin-right: 0;
  }

  .selector.selected {
    background-color: darken(white, 0%);
    // color: black;
    opacity: 1;
    box-shadow: 0px 0px 7px rgba(0,0,0,.33);
  }

  .selector:hover {
    background-color: darken(white, 0%);
  }

}


.selector-cont.disabled {
  cursor: initial !important;

  .selector, .selector:hover {
    pointer-events: none !important;
  }
}

.pagination-cont.disabled {
  cursor: initial !important;

  * {
    pointer-events: none !important;
  }
}




.button.pill {
  font-size: 8px;
}
.button.pill.first {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button.pill.last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.button.outline {
  background: none; 
  color: $sage;
  border-color: $eucalyptus;
}
.button.outline:hover {
  border-color: lighten($yellow, 10%);
  background-color: lighten($yellow, 20);
  color: darken($sage, 25)
  // background-color: rgba(255,255,255,.8);
}


.button.danger {
  background: $dangerRed;
  border-color: $dangerRed;
  color: white;
}
.button.danger:hover {
  background-color: darken($dangerRed, 15);
}

.center {
  text-align: center;
}

table.print-head {
  border-collapse: collapse;
  width: 8.5in;
  max-width: 8.5in;

  tr {
    th {
      background: rgba(0,0,0,.1);
      text-align: right;
      padding-right: .5em;
      width: 1%;
      white-space: nowrap;
    }
    td {
      padding-left: .5em;
    }

    th, td {
      border-bottom: 1px solid rgba(0,0,0,.2);
    }
  }
}


table.print {
  // table-layout: fixed;
  border-collapse: collapse;
  width: 8.5in;
  max-width: 8.5in;
  // width: 100%;

  tr {

    td, th {
      padding: 2px;
      word-break: break-all;
    }
    th {
      border-bottom: 2px solid rgba(0,0,0,.33);
    }

    td {
      border-bottom: 1px solid rgba(0,0,0,.33);
    }
  }
}

.avatar-cont {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue;
  color: white;
  border-radius: 50%;
}



nav.main {
  .office.menu-cont {
    .menu {
      max-height: 80vh;
      overflow-y: auto;
    }
    
  }

  .menu-cont.nonclicky {
    cursor: auto;
  }
  .menu-cont {
    padding: .5em;
    transition: .2s all;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
  
    .menu {
      position: absolute;
      top: 100%;
      right: 0;
      padding: 1em;
      border-radius: .5em;
      background: white;
      box-shadow: 0 .1em 1em rgba(0,0,0,.1);

      

      .item {
        white-space: nowrap;
        padding: .5em;
        transition: .2s all;
        border-radius: .5em;
        cursor: pointer;
        padding-right: 2em;
      }
      .item:hover {
        background: rgba(0,0,0,.05);
      }
    }
  }
  .menu-cont:hover {
    background: rgba(0,0,0,.05);
  }
  .menu-cont.nonclicky:hover {
    background: none;
  }
}

.lightgray {
  color: rgba(0,0,0,.1)
}
.medgray {
  color: rgba(0,0,0,.25)
}
.negative-red {
  color: darkred !important;
  background-color: #ffadad !important;
  padding: 4px;
}

.autocomplete-disabled {
  pointer-events: none !important;
  cursor: not-allowed;
  // opacity: .75;

  input[type=text] {
    background: rgba(0,0,0,.1);
    color: gray;
  }
  // background: rgba(0,0,0,.025);
  // color: rgba(0,0,0,1);
  // border: 2px solid rgba(0, 0, 0, 0.02);
}

.autocomplete {
  position: relative;

  
  
  .menu-cont {
    transition: .2s all;
    border-radius: .5em;
    cursor: pointer;
    position: relative;

    .menu.fixed {
      position: fixed;
    }

    .menu.auto-min-width {
      min-width: auto;
    }
  
    .menu {
      position: absolute;
      // top: 100%;
      // left: 0;
      padding: .25em;
      border-radius: .5em;
      background: white;
      box-shadow: 0 .1em 1em rgba(0,0,0,.33);
      max-height: 20em;
      overflow: auto;
      z-index: 99999999;
      min-width: 100%;

      .item.existing-item, .item.highlighted.existing-item {
        background: lighten($yellow, 20);
        font-weight: bold;
      }

      .item.highlighted {
        // border: 2px solid lighten($yellow, 20);
      }

      .item {
        white-space: nowrap;
        padding: .5em;
        margin: .25em 0;
        transition: .2s all;
        border-radius: .5em;
        cursor: pointer;
        padding-right: 2em;
        // min-width: 100%;
      }
      .item:hover {
        background: rgba(0,0,0,.1);
      }
    }
  }
}

.noti-cont {
  position: relative;

  .noti-number {
    background: $blue;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    font-size: .66rem;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0em;
    right: -4px;
    position: absolute;
  }
}