$yellow: #6B7975;
// $yellow: #f6c346;
$sage: #6B7975;
$eucalyptus: #B0B6B4;
$red: #f23800;
$blue: #53b3d5;
$ruby: #a60000;
$orange: #ff6434;
$red: #ec1c23;  
$darkBlue: #252364;
$darkBlue2: #216a95;
$view-as-height: 50px;
$top-inner-nav-height: 65px;
$top-nav-height: 65px;
$admin-top-inner-nav-height: 65px;
$admin-top-nav-height: 115px;

$side-nav-width: 200px;
$borderRadius: 4px;
$dangerRed: rgb(173, 34, 24);
$successGreen: rgb(16, 114, 48);

$select-min-width: 15rem;

@mixin mobile {
  @media (max-width: 599px) { @content; }
}
@mixin desktop {
  @media (min-width: 600px) { @content; }
}


