@import "./Constants.scss";

.react-calendar {
  background: white;
  border-radius: .5em;
  border: none;
  outline: none;
  padding: 1em;
  box-shadow: 0 .5em .5em rgba(0,0,0,.1);
  position: absolute;


  button {
    color: black;
  }
  .react-calendar__tile--active {
    background: $yellow;
    color: white;
  }
  .react-calendar__tile--now {
    border: 2px dashed rgba(0,0,0,.1);
    background: none;
  }
  .react-calendar__tile--now.react-calendar__tile--active  {
    background: $yellow;
    color: white;
  }
  .react-calendar__tile--now:enabled:focus {
    background: rgba(0,0,0,.05);
  }
  .react-calendar__tile--now:enabled:hover {
    background: rgba(0,0,0,.1);
  }
}
.react-date-picker {
  width: 100%;
}

.react-date-picker__calendar {
  top: 100% !important;
}

table.vertical {
  .react-date-picker__wrapper {
    padding: .33em;
  }
}
.react-date-picker__inputGroup {
  white-space: nowrap;
}
.react-date-picker__inputGroup__input {
  // width: 2em !important;
  min-width: 1.5em !important;
  overflow: visible;
  padding: 0 !important;
  text-align: center;
  padding-left: 2px;
}
.react-date-picker__inputGroup__input::placeholder {

  letter-spacing: -1px;
  text-align: center;
  padding-left: 2px;
}
.react-date-picker__inputGroup__year {
  // width: 2em !important;
  min-width: 3.1em !important;
}

.react-date-picker--disabled {

  .react-date-picker__wrapper {
    pointer-events: none;
    opacity: .33;
    cursor: not-allowed;

    opacity: .7;
    background: rgba(0,0,0,.05);
    color: rgba(0,0,0,.75);
  }
}





.react-date-picker__wrapper {
  border: 2px solid rgba(0,0,0,.1);
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
  // min-width: 9rem;
  border-radius: $borderRadius;
  font-size: .7rem;
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  height: 25px;
  padding: 0;
}