.modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
  cursor: pointer;
  z-index: 100001;
}
.modal-cont {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  z-index: 100001;
  font-size: initial;

  .modal-content.large {
    width: 97vw;
    height: 97vh;
    max-width: 97vw;
    max-height: 97vh;
    top: 1.5vh;
  }

  .modal-content.small {
    max-width: 500px;
    // top: 10%;
    top: 10vh;
  }
  .modal-content.medium {

  }
  .modal-content {
    pointer-events: all;
    position: relative;
    background:
    white;
    width: 100%;
    max-width: 900px;
    top: 10%;
    top: 5vh;
    max-height: 90vh;
    overflow: auto;
    border-radius: 1rem;

    .modal-inner-content {
      // padding-bottom: 100px;
      height: 100%;

      .menu {
        position: fixed !important;
        /* min-width: auto; */
        box-sizing: border-box;
        min-width: auto;
        /* overflow-x: visible; */
      }
    }
    

    .close {
      // position: absolute;
      // right: 0; 
      // top: 0;
    }

    h1, h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  
  }
  
}
.modal-header{
  position: sticky;
  top: 0;
  z-index: 1;
  background:white;
  padding: 1em;
  left: 0;
  right: 0;

}
.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
  padding: 1em;
  left: 0;
  right: 0;
  // border-top: 1px solid rgba(0,0,0,.5);
}
.modal-controls {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em;
  background: white;
  right: 0;
}

